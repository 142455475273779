<template>
  <router-link :to="admin ? {name: 'Video-Write', params: {editModel: collectionData} } : collectionData['slug']" append>
    <div class="video-collection-container">
      <div class="video-collection-image">
        <img :src="collectionData['image']" alt="">
      </div>
      <div class="video-collection-description">
        <h3 class="text--primary">{{ collectionData['title'][+($i18n.locale !== 'sv')] || collectionData['title'][0] }}</h3>
        <p class="text-light" style="font-size: 16px; margin: 5px 0">{{ collectionData['videos'].length }} video{{ collectionData['videos'].length > 1 ? 's' : '' }}</p>
        <p style="margin: 10px 0 0 0; white-space: pre-wrap;">{{ collectionData['description'][+($i18n.locale !== 'sv')] || collectionData['description'][0] }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    collectionData: {},
    admin: false,
  },
}
</script>

<style>
.video-collection-container {
  aspect-ratio: 0.864;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.116);
  /* box-shadow: none; */
  cursor: pointer;
  text-align: left;
  /* border: 1px solid rgba(0, 0, 0, 0.075); */

  transition: transform 100ms ease-out, box-shadow 100ms ease-out;
  /* transition: box-shadow 100ms ease; */
}

.video-collection-description > p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.video-collection-container:hover {
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.075);
  /* box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.075); */
  transform: translateY(2px);
}

.video-collection-image {
  height: 50%;
}

.video-collection-image > img {
  min-width: none;
  width: 100%;
  height: 100%;
  max-height: 450px;
  object-fit: cover;
}

.video-collection-description {
  padding: 20px;
}
</style>