<template>
  <div>
    <div style="display: flex" class="full-width-padding">
      <p class="text-hoverable" @click="$router.go(-1)" style="display: flex; justify-content: flex-start; align-items: center">
        <BackIcon style="margin-right: 10px"/>Tillbaka
      </p>
    </div>
    <h1>Samlingar</h1>
    <router-link to="videos-write"><button class="auth">Ny samling</button></router-link>
    <div class="video-collection-grid full-width-padding" style="margin-top: 80px">
      <div v-for="(collection, index) in collections" :key="index">
        <video-collection-card :collectionData="collection" :admin="true" />
        <p class="auth text-hoverable text--warning" style="padding-top: 10px" @click="removeCollection(collection)">Ta bort</p>
      </div>
    </div>
  </div>
</template>

<script>
import BackIcon from '../../assets/icons/back.svg'
import VideoCollectionCard from '../../components/videos/VideoCollectionCard.vue'

export default {
  components: {
    BackIcon,
    VideoCollectionCard,
  },
  methods: {
    removeCollection(collection) {
      this.$store.dispatch('deleteCollection', { 'collection': collection })
    }
  },
  metaInfo() {
    return {
      title: 'Admin - Videobibliotek'
    }
  },
  computed: {
    collections() {
      return this.$store.state.videoCollections
    }
  },
}
</script>

<style>
.video-collection-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 20fr));
  column-gap: 70px;
  row-gap: 70px;
  justify-content: flex-start;
}
</style>